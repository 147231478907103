import React, {useEffect, useRef, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import UserService from "../../services/user.service";
import '../../Print.css';
import { logOut} from "../../helpers/logout";
import {Button, Divider, Grid, Toolbar} from "@material-ui/core";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {PDFExport} from '@progress/kendo-react-pdf';
import {NotificationManager} from "react-notifications";
import TextInput from "../form/input/TextInput";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarButtons: {
        width: "100%",
        '& .MuiFormControl-root': {
            margin: theme.spacing(1)
        }
    },
    marginElement: {
        margin: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    cardMedia: {
        height: 200,
        width: 150,
        borderRadius: 10
    },
    cardContent: {
        flexGrow: 1,
    },
    prayerInput: {
        width: '100%'
    },
}));


const CardsGenerator = (props) => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper);

    const pdfRef = useRef(null);
    const [funeral, setFuneral] = useState({
        contracting_authority: '',
        ca_address: '',
        ca_zip: '',
        ca_city: '',
        ca_state: '',
        ca_nationality: '',
        ca_email: '',
        ca_phone: '',
        ca_id: '',
        ca_birth: '',
        ca_relationship: '',
        deceased: '',
        d_address: '',
        d_zip: '',
        d_city: '',
        d_state: '',
        d_place_birth: '',
        d_birth: '',
        d_gender: '',
        d_marital_status: '',
        d_death: '',
        d_place_death: '',
        d_id: '',
        d_nationality: '',
        d_education: '',
        d_last_job: '',
        d_wife_husband: '',
        d_wife_husband_id: '',
        planned_pickup_place: '',
        planned_pickup: '',
        town_funeral: '',
        place_funeral: '',
        date_funeral: '',
        coffin_arrival: '',
        coffin_arrival_place: '',
        signatures: {},
        product_entities: [],
        service_entities: []
    });
    const [success, setSuccess] = useState(false);
    const [prayer, setPrayer] = useState('');

    const handlePrayer = (e) => {
        setPrayer(e.target.value)
    }

    const [user, setUser] = useState(null);
    const [d_death, setD_death] = useState('');
    const [d_birth, setD_birth] = useState('');
    const [age, setAge] = useState(0);

    const funeral_id = useParams();
    const user_local = JSON.parse(localStorage.getItem('user')).id;

    useEffect(() => {
        UserService.getUser(user_local).then(
            (response) => {
                setUser(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
        UserService.getFuneral(funeral_id.funeral_id).then(
            (response) => {
                setFuneral(response.data);
                if(response.data.d_death){
                    setD_death(new Date(response.data.d_death).toShortFormat());
                    setAge(calculate_age(response.data.d_birth,response.data.d_death))
                }
                if(response.data.d_birth){
                    setD_birth(new Date(response.data.d_birth).toShortFormat());
                }
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [funeral_id.funeral_id, user_local, props]);

    const calculate_age = (birth, death) => {
        let diff_ms = new Date(death).getTime() - new Date(birth).getTime();
        let age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    const handleExport = () => {
        pdfRef.current.save();
    }

    const showDelegation = () => {
        if (user) {
            return <div style={{border: '1px solid'}}>
                <PDFExport ref={pdfRef} paperSize="A4" scale={0.75}
                           fileName={`karticky-${funeral.deceased.replace(' ', '')}`}>
                    <div className="a4-paper-cards">
                        <div className="mt-top-card-whole-first">
                            <div className="column-3">
                                <div className="card-1">
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={user.logo}/>
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={funeral.decease_photo}/>
                                </div>
                                <div className="card-2">
                                    <span style={{fontWeight: "bold", fontSize: '14px'}}>{funeral.deceased}</span>
                                    <span style={{fontSize: '12px'}}>*{d_birth} -  ✝{d_death} ({age})</span>
                                </div>
                                <div className="card-3">
                                    <span style={{display: 'block',padding: "5px", fontSize: '11px'}}>{prayer}</span>
                                </div>
                            </div>
                            <div className="column-3">
                                <div className="card-1">
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={user.logo}/>
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={funeral.decease_photo}/>
                                </div>
                                <div className="card-2">
                                    <span style={{fontWeight: "bold", fontSize: '14px'}}>{funeral.deceased}</span>
                                    <span style={{fontSize: '12px'}}>*{d_birth} -  ✝{d_death} ({age})</span>
                                </div>
                                <div className="card-3">
                                    <span style={{display: 'block',padding: "5px", fontSize: '11px'}}>{prayer}</span>
                                </div>
                            </div>
                            <div className="column-3">
                            <div className="card-1">
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={user.logo}/>
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={funeral.decease_photo}/>
                                </div>
                                <div className="card-2">
                                    <span style={{fontWeight: "bold", fontSize: '14px'}}>{funeral.deceased}</span>
                                    <span style={{fontSize: '12px'}}>*{d_birth} -  ✝{d_death} ({age})</span>
                                </div>
                                <div className="card-3">
                                    <span style={{display: 'block',padding: "5px", fontSize: '11px'}}>{prayer}</span>
                                </div>
                            </div>
                        </div>


                        <div className="mt-top-card-whole-second">
                            <div className="column-3">
                                <div className="card-1">
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={user.logo}/>
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={funeral.decease_photo}/>
                                </div>
                                <div className="card-2">
                                    <span style={{fontWeight: "bold", fontSize: '14px'}}>{funeral.deceased}</span>
                                    <span style={{fontSize: '12px'}}>*{d_birth} -  ✝{d_death} ({age})</span>
                                </div>
                                <div className="card-3">
                                    <span style={{display: 'block',padding: "5px", fontSize: '11px'}}>{prayer}</span>
                                </div>
                            </div>
                            <div className="column-3">
                                <div className="card-1">
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={user.logo}/>
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={funeral.decease_photo}/>
                                </div>
                                <div className="card-2">
                                    <span style={{fontWeight: "bold", fontSize: '14px'}}>{funeral.deceased}</span>
                                    <span style={{fontSize: '12px'}}>*{d_birth} -  ✝{d_death} ({age})</span>
                                </div>
                                <div className="card-3">
                                    <span style={{display: 'block',padding: "5px", fontSize: '11px'}}>{prayer}</span>
                                </div>
                            </div>
                            <div className="column-3">
                                <div className="card-1">
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={user.logo}/>
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={funeral.decease_photo}/>
                                </div>
                                <div className="card-2">
                                    <span style={{fontWeight: "bold", fontSize: '14px'}}>{funeral.deceased}</span>
                                    <span style={{fontSize: '12px'}}>*{d_birth} -  ✝{d_death} ({age})</span>
                                </div>
                                <div className="card-3">
                                    <span style={{display: 'block',padding: "5px", fontSize: '11px'}}>{prayer}</span>
                                </div>
                            </div>
                        </div>

                        <div className="mt-top-card-whole-second">
                            <div className="column-3">
                                <div className="card-1">
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={user.logo}/>
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={funeral.decease_photo}/>
                                </div>
                                <div className="card-2">
                                    <span style={{fontWeight: "bold", fontSize: '14px'}}>{funeral.deceased}</span>
                                    <span style={{fontSize: '12px'}}>*{d_birth} -  ✝{d_death} ({age})</span>
                                </div>
                                <div className="card-3">
                                    <span style={{display: 'block',padding: "5px", fontSize: '11px'}}>{prayer}</span>
                                </div>
                            </div>
                            <div className="column-3">
                                <div className="card-1">
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={user.logo}/>
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={funeral.decease_photo}/>
                                </div>
                                <div className="card-2">
                                    <span style={{fontWeight: "bold", fontSize: '14px'}}>{funeral.deceased}</span>
                                    <span style={{fontSize: '12px'}}>*{d_birth} -  ✝{d_death} ({age})</span>
                                </div>
                                <div className="card-3">
                                    <span style={{display: 'block',padding: "5px", fontSize: '11px'}}>{prayer}</span>
                                </div>
                            </div>
                            <div className="column-3">
                                <div className="card-1">
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={user.logo}/>
                                    <img crossorigin="anonymous" className="a6-wrapper-content-img" width={100} src={funeral.decease_photo}/>
                                </div>
                                <div className="card-2">
                                    <span style={{fontWeight: "bold", fontSize: '14px'}}>{funeral.deceased}</span>
                                    <span style={{fontSize: '12px'}}>*{d_birth} -  ✝{d_death} ({age})</span>
                                </div>
                                <div className="card-3">
                                    <span style={{display: 'block',padding: "5px", fontSize: '11px'}}>{prayer}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </PDFExport>
            </div>
        }
        return <></>
    }

    return (
        <Container maxWidth="lg" className={classes.seeMore}>
            <div className={classes.root}>
                <Paper className={fixedHeightPaper}>
                    <Grid container>
                        <Grid xs={12} sm={4} md={3} lg={3} item container>
                            <Toolbar>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    hidden={true}
                                    className={classes.button}
                                    onClick={() => {
                                        setSuccess(true)
                                    }}
                                >
                                    Späť na detaily
                                </Button>
                            </Toolbar>
                        </Grid>
                        <Grid xs={12} sm={8} md={9} lg={9} item container justifyContent='flex-end'>
                            <Toolbar className={classes.toolbarButtons}>
                                <TextInput
                                    className={classes.prayerInput}
                                    label="Modlitba"
                                    name={'prayer'}
                                    value={prayer}
                                    onChange={handlePrayer}
                                />
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Pdf export">
                                        <PictureAsPdfIcon
                                            fontSize="small"
                                            onClick={handleExport}
                                        />
                                    </Tooltip>
                                </ActionButton>
                            </Toolbar>
                        </Grid>
                    </Grid>

                    <Divider/>
                    <Grid className={classes.marginTop}
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                    >
                        {showDelegation()}
                    </Grid>
                </Paper>
            </div>
            {success ? <Redirect to={{
                pathname: `/pohreb/${funeral_id.funeral_id}/info`,
                state: {from: props.location, number: 5}
            }}/> : <></>}
        </Container>
    )
}
export default CardsGenerator;

